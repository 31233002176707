@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  color: gray;
}

.financesHeader {
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 8px;
}
.showAllSpan {
  color: #333;
  font-size: 0.9rem;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.open-register {
  /* height: auto;
  width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: auto;
  /* box-shadow: 0px 0px 6px;
  border-radius: 10px; */
  margin-top: 5%;
}

.choose-container {
  display: flex;
  flex-direction: row;
}

.account-type {
  box-shadow: 0px 0px 5px rgb(218, 218, 218);
  border-radius: 10px;
  margin: 10px;
  margin-top: 20px;
  padding: 35px 25px 25px 25px;
  text-align: center;
  position: relative;
  flex: 1 1;
}
.account-type:hover {
  border: 2px solid #0d6efd;
}

.is-checked {
  position: absolute;
  color: #0d6efd;
  right: 10px;
  top: 5px;
}

.continue-btn {
  width: 85%;
  padding: 5px;
  margin: auto;
  border-radius: 10px;
  background-color: blue;
  color: white;
  text-align: center;
}
.model-style {
  width: 80%;
}
.col-form-all {
  /* max-height: 500px;
  overflow-y: scroll; */
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;

  /* box-shadow: 0px 0px 3px rgb(189, 187, 187);
  border-radius: 10px; */
  margin-top: 5%;
  margin-bottom: 5%;
}

.form-progress-indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  margin-top: 5px;
  /* border: 2px solid #0d6efd; */
  border-radius: 40px;
  /* box-shadow: 0px 0px 3px rgb(204, 203, 203); */
  /* background-color: brown; */
}
.form-progress-indicator {
  margin-top: 20px;
}
.info-form-form {
  width: 100%;
}
.show-form {
  width: 100%;
  padding: 20px;
}
.form {
  width: 100%;
}
.form-control {
  width: 100%;
}
.form-group {
  margin-bottom: 5px;
}
.btn {
  width: 100%;
  margin-top: 10px;
}

.selecttype {
  width: 300px;
  /* margin-top: 5px; */
}
.drop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid-container-form {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: auto auto;
}
.grid-item {
  width: 300px;
}
.grid-container {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: auto auto;
}

.item {
  border: solid 1px;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
}
.item:hover {
  cursor: pointer;
  border: 3px solid #0d6efd;
}
.Multicaxia {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.col-1-frm {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* margin: auto; */
  justify-content: center;
  justify-content: spae-around;
}
.btn-col-1-frm {
  width: 40%;
  border: none;
  padding: 5px;
  margin: auto;
  border-radius: 10px;
}
.payment-form {
  /* background-color: blue; */
  width: 600px;
  height: 260px;
  /* background-color: blue; */
}
.modal-content span {
  color: black !important;
}

/* ------- Landing Page ---------*/
.landing-page-container {
  width: 100%;
  height: 30em;
}

.cover-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 34em;
  width: 100%;
  @media screen and (min-width: 2560px) {
    height: 56em;
  }
  @media screen and (max-width: 425px) {
    height: 28em;
  }
}
.cover-img-content {
  position: relative;
  /* //top: 12em; */
  top: 40%;
  color: white;
  text-align: left;
  font-weight: bold;
  font-family: Sen, sans-serif;
  margin: 0 3em;
  background-color: #63636385;
  padding: 1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  @media screen and (max-width: 425px) {
    top: 2em;
  }
}

.landing-page-cards-wrap {
  margin: 3em auto;
  margin-top: 9em;
}
.card {
  border-radius: 4px;
  background: #fff;
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05); */
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow;
  /* padding: 44px 80px 18px 26px; */
  cursor: pointer;
  font-family: Sen, sans-serif;
  border: 0 !important;
}

.card:hover {
  /* transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
}

.card h3 {
  font-weight: 600;
  margin-top: 20px;
  font-size: 21px;
}

.card img {
  /* position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px; */
  max-height: 245px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.card-1 {
  /* background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  min-height: 14em; */
  height: 333px !important;
}

.card-2 {
  /* background-image: url(https://ionicframework.com/img/getting-started/components-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  min-height: 14em; */
  height: 333px !important;
}

.card-3 {
  /* background-image: url(https://ionicframework.com/img/getting-started/theming-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  min-height: 14em; */
  height: 333px !important;
}

@media (max-width: 990px) {
  .card {
    margin: 20px;
  }
}

/* -------- Footer--------- */
.site-footer {
  height: auto;
  background-color: #0c9cfb;
  padding: 5px;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  text-align: left;
  font-family: Sen, sans-serif;
}
.footer-container {
  height: 15em;
  padding-left: 5em;
  padding-right: 5em;
}
.footer-container-bottom {
  height: 10em;
  padding-left: 5em;
  padding-right: 5em;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #33c7cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: white;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: whitesmoke;

  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.social-icons{
  display: flex;
  justify-content: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 0.5em;
  background-color: whitesmoke;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 5px;
  }
  .landing-page-cards-wrap {
    margin: 11em auto;
  }
  .spacing{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .footer-div{
    display: block !important;
  }
  
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 50%;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.youtube:hover {
  background-color: #d91b1b;
}
.social-icons a.whatsapp:hover {
  background-color: #35bb2f;
}
.social-icons a.instagram:hover {
  background-color: #ec4f8a;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
  .cover-img-apps a div{
    width: 7em !important;
  }
  
}

/* ----- Header---------- */
.header {
  width: 100%;
  height: 5em;
  padding: 5px;
}

.header-top {
  display: flex;
  width: 90%;
  height: 4em;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-content: center;
}

.header-nav {
  width: 30%;
  margin: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: gray;
  cursor: pointer;
}

.header-addProduct {
  /* background-color: aquamarine; */
  width: 100%;
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.page-item.active .page-link {
  background-color: #049eff !important;
  color: "#000" !important;
  border-color: #049eff !important;
}
.spacing{
  flex-direction: column !important;
  justify-content: center !important;
}
.height-fix{
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.spacing h1{
  text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 40px;
}
@media (min-width: 350px) and (max-width:499px) {
  .footer-container-bottom{
    margin-top: 305px;
  }
}
@media (min-width: 500px) and (max-width:599px) {
  .footer-container-bottom{
    margin-top: 246px;
  }
}
@media (min-width: 600px) and (max-width:900px) {
  .footer-container-bottom{
    margin-top:192px;
  }
}
@media (min-width:1600px){
  .card{
    width: 32rem !important;
  }
  .card img{
    max-height: 330px !important;
  }
  .height-fix{
    grid-gap: 12px;
    gap: 12px;
  }
  .container{
    height: 100% !important;
    max-width: 1610px !important;
  }
  .landing-page-cards-wrap{
    margin-top: 8em;
  }
}
@media (min-width:900px){
  .container{
    height: 100% !important;
  }
}
/* for chrome */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lavUay {
  margin: 0px !important;
}
.sidebar-menu-item :hover {
  background-color: rgba(14, 75, 116, 0.4) !important;
}
.item-content:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.left-col {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
}

.center-col {
  flex: 1 1;
  background: #aaa;
  overflow-y: scroll;
}

.right-col {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.side-drawer {
  /* padding-top: 4%; */
  background-color: #049eff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;
  transform: translateX(-5%);
  transition: transform 0.3s ease-out;
  min-width: 100px;
}

.open-sidedrawer {
  min-width: 280px;
  /* padding-top: 4%; */
  background-color: #049eff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}

.open-sidedrawer ul a:hover {
  background-color: rgba(14, 75, 116, 0.4) !important;
}
/* .side-drawer {
  padding-top: 4%;
  height: 100vh;
  background-color: #049eff;
  box-shadow: "0 4px 16px 0 rgba(0,0,0,0.3)";
  position: sticky;
  min-width: 80px;
  top: 0;
  overflow: scroll;
  transition: all 0.1s ease-in-out;
}
.open-sidedrawer {
  min-width: 220px;
  height: 100vh;
} */

@media screen and (max-width: 900px) {
  .side-drawer {
    min-width: 100px !important;
  }
}

@media screen and (max-width: 900px) {
  .side-drawer {
    min-width: 100px !important;
    padding-top: 10%;
  }
}
@media screen and (min-width: 902px) and (max-width: 1199) {
  .side-drawer {
    padding-top: 20%;
  }
}

.bg-light {
  background-color: #fff !important;
  /* padding-top: "2%";
  padding-bottom: "2%"; */
  /* height: 60px !important; */
}

.modal-content span {
  color: red;
}

.switchClass {
  color: red;
}

.switchClass:hover {
  color: rgb(167, 81, 81);
}

@media screen and (max-width: 700px) {
  .navbarIcons {
    padding-left: 50px !important;
  }
}

.close {
  display: none;
}

#dropdown-basic::after {
  display: none;
}

#profile-icon::after {
  display: none;
}

#profile-icon:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgb(13 110 253 / 25%) !important;
}

#profile-icon {
  background-color: #f8f9fa;
  border: 0px;
  margin: auto;
}

.profile-menu {
  position: absolute;
  right: 0.5%;
}

.dropdownMenu {
  padding-left: 20px;
  width: 500px;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  right: -110px;
  top: 55px;
}

.emptyDropdownMenu {
  padding-left: 20px;
  right: -70px;
  top: 55px;
}

.navLink {
  color: #333;
  font-weight: bold;
}

.modal-open {
  padding-right: 0px !important;
}

.red {
  color: red;
}
/* .business-input {
  width: 90%;
}
.bank-input {
  width: 50%;
} */
.payment-method-btn {
  width: 30px;
  background-color: red;
}
.signin-input {
  width: 94% !important;
  border: 0px solid gray !important;
  box-shadow: none !important;
}
.signin-input:focus {
  border: none;
  outline: none;
}
.eye {
  color: gray !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bankOptContainer {
  border: 2px solid rgb(92, 91, 91);
  border-radius: 14px;
  padding: 50px 150px;
}
.input-box {
  display: flex;
  align-items: center;
  /* max-width: 300px; */
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 100;
  font-size: 14px;
  color: #9f9c99;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box textarea {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border-color: #86b7fe;
  box-shadow: 0px 0px 0px 4px rgba(194, 219, 254, 0.75);
}

.statsCard{width:24%;background-color:#fff;border:1px solid #d8d8d8;position:relative;border-radius:4px;min-height:150px;padding:15px;float:left;margin-right:5px;box-sizing:border-box}.myh5{margin-bottom:0px;color:#6c757d;font-size:1.2rem}@media (max-width: 1024px){.statsCard{width:45%;min-height:120px}}@media (max-width: 562px){.statsCard{width:80%;margin-bottom:5px}}

.rangeTitle{margin:0;padding:0;font-weight:bold;font-size:12px}

.MainContainer{border-radius:8px;background-color:#fff}.row{height:100%;justify-content:center;align-items:center}#dropdown-menu-align-right{background-color:#fff;color:#850a0a;border:solid 1px #cccccc;font-size:0.8rem;padding:8px 20px}.datePicker{width:40px;height:40px;margin:2px 0 2px 24px;padding:10px 11px;border-radius:4px;background-color:#f2f2f2}.noDataContainer{text-align:center}.noDataContainer p{color:#a9a9a9;font-size:22px}

.imgUploadLbl{border:2px dashed black;padding:15px 0;display:flex;justify-content:center;align-items:center;flex-direction:column;cursor:pointer;border-radius:8px;width:67px;height:67px}.imgUploadLbl span{font-size:20px;font-weight:bold;color:#000;padding-bottom:0;line-height:16px}.imgUploadLbl p{font-size:10px;margin:0;padding:0}.addAtrTxt{text-align:center;border:2px dashed;border-radius:4px;cursor:pointer}.tglBtn{margin:20px 0px;background-color:#049eff !important;border:none !important;border-width:0;width:100%;display:flex;flex-direction:row;align-items:center;justify-content:center}

.imgUploadLbl{border:2px dashed black;padding:15px 0;display:flex;justify-content:center;align-items:center;font-size:22px;font-weight:bold;cursor:pointer;border-radius:8px;width:67px;height:67px}.radioInp{vertical-align:middle;margin-right:2px}.radioLbl{font-size:14px}.inpLblTxt{font-size:12px;font-weight:bold}.inputField{border:1px solid #ced4da;border-radius:4px;height:34px;padding-left:8px}.inputTxtarea{border:1px solid #ced4da;border-radius:4px;padding-left:8px;width:100%}

