.red {
  color: red;
}
/* .business-input {
  width: 90%;
}
.bank-input {
  width: 50%;
} */
.payment-method-btn {
  width: 30px;
  background-color: red;
}
.signin-input {
  width: 94% !important;
  border: 0px solid gray !important;
  box-shadow: none !important;
}
.signin-input:focus {
  border: none;
  outline: none;
}
.eye {
  color: gray !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bankOptContainer {
  border: 2px solid rgb(92, 91, 91);
  border-radius: 14px;
  padding: 50px 150px;
}
.input-box {
  display: flex;
  align-items: center;
  /* max-width: 300px; */
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 100;
  font-size: 14px;
  color: #9f9c99;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box textarea {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border-color: #86b7fe;
  box-shadow: 0px 0px 0px 4px rgba(194, 219, 254, 0.75);
}
