.imgUploadLbl {
  border: 2px dashed black;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  width: 67px;
  height: 67px;
}

.radioInp {
  vertical-align: middle;
  margin-right: 2px;
}
.radioLbl {
  font-size: 14px;
}
.inpLblTxt {
  font-size: 12px;
  font-weight: bold;
}

.inputField {
  border: 1px solid #ced4da;
  border-radius: 4px;
  height: 34px;
  padding-left: 8px;
}
.inputTxtarea {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 8px;
  width: 100%;
}
