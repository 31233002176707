@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  color: gray;
}

.financesHeader {
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 8px;
}
.showAllSpan {
  color: #333;
  font-size: 0.9rem;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.open-register {
  /* height: auto;
  width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: auto;
  /* box-shadow: 0px 0px 6px;
  border-radius: 10px; */
  margin-top: 5%;
}

.choose-container {
  display: flex;
  flex-direction: row;
}

.account-type {
  box-shadow: 0px 0px 5px rgb(218, 218, 218);
  border-radius: 10px;
  margin: 10px;
  margin-top: 20px;
  padding: 35px 25px 25px 25px;
  text-align: center;
  position: relative;
  flex: 1;
}
.account-type:hover {
  border: 2px solid #0d6efd;
}

.is-checked {
  position: absolute;
  color: #0d6efd;
  right: 10px;
  top: 5px;
}

.continue-btn {
  width: 85%;
  padding: 5px;
  margin: auto;
  border-radius: 10px;
  background-color: blue;
  color: white;
  text-align: center;
}
.model-style {
  width: 80%;
}
.col-form-all {
  /* max-height: 500px;
  overflow-y: scroll; */
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;

  /* box-shadow: 0px 0px 3px rgb(189, 187, 187);
  border-radius: 10px; */
  margin-top: 5%;
  margin-bottom: 5%;
}

.form-progress-indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  margin-top: 5px;
  /* border: 2px solid #0d6efd; */
  border-radius: 40px;
  /* box-shadow: 0px 0px 3px rgb(204, 203, 203); */
  /* background-color: brown; */
}
.form-progress-indicator {
  margin-top: 20px;
}
.info-form-form {
  width: 100%;
}
.show-form {
  width: 100%;
  padding: 20px;
}
.form {
  width: 100%;
}
.form-control {
  width: 100%;
}
.form-group {
  margin-bottom: 5px;
}
.btn {
  width: 100%;
  margin-top: 10px;
}

.selecttype {
  width: 300px;
  /* margin-top: 5px; */
}
.drop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid-container-form {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: auto auto;
}
.grid-item {
  width: 300px;
}
.grid-container {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: auto auto;
}

.item {
  border: solid 1px;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
}
.item:hover {
  cursor: pointer;
  border: 3px solid #0d6efd;
}
.Multicaxia {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.col-1-frm {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* margin: auto; */
  justify-content: center;
  justify-content: spae-around;
}
.btn-col-1-frm {
  width: 40%;
  border: none;
  padding: 5px;
  margin: auto;
  border-radius: 10px;
}
.payment-form {
  /* background-color: blue; */
  width: 600px;
  height: 260px;
  /* background-color: blue; */
}
.modal-content span {
  color: black !important;
}

/* ------- Landing Page ---------*/
.landing-page-container {
  width: 100%;
  height: 30em;
}

.cover-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 34em;
  width: 100%;
  @media screen and (min-width: 2560px) {
    height: 56em;
  }
  @media screen and (max-width: 425px) {
    height: 28em;
  }
}
.cover-img-content {
  position: relative;
  /* //top: 12em; */
  top: 40%;
  color: white;
  text-align: left;
  font-weight: bold;
  font-family: Sen, sans-serif;
  margin: 0 3em;
  background-color: #63636385;
  padding: 1em;
  width: fit-content;

  @media screen and (max-width: 425px) {
    top: 2em;
  }
}

.landing-page-cards-wrap {
  margin: 3em auto;
  margin-top: 9em;
}
.card {
  border-radius: 4px;
  background: #fff;
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05); */
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  /* padding: 44px 80px 18px 26px; */
  cursor: pointer;
  font-family: Sen, sans-serif;
  border: 0 !important;
}

.card:hover {
  /* transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
}

.card h3 {
  font-weight: 600;
  margin-top: 20px;
  font-size: 21px;
}

.card img {
  /* position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px; */
  max-height: 245px;
  max-width: fit-content;
}

.card-1 {
  /* background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  min-height: 14em; */
  height: 333px !important;
}

.card-2 {
  /* background-image: url(https://ionicframework.com/img/getting-started/components-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  min-height: 14em; */
  height: 333px !important;
}

.card-3 {
  /* background-image: url(https://ionicframework.com/img/getting-started/theming-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  min-height: 14em; */
  height: 333px !important;
}

@media (max-width: 990px) {
  .card {
    margin: 20px;
  }
}

/* -------- Footer--------- */
.site-footer {
  height: auto;
  background-color: #0c9cfb;
  padding: 5px;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  text-align: left;
  font-family: Sen, sans-serif;
}
.footer-container {
  height: 15em;
  padding-left: 5em;
  padding-right: 5em;
}
.footer-container-bottom {
  height: 10em;
  padding-left: 5em;
  padding-right: 5em;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #33c7cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: white;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: whitesmoke;

  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.social-icons{
  display: flex;
  justify-content: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 0.5em;
  background-color: whitesmoke;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 5px;
  }
  .landing-page-cards-wrap {
    margin: 11em auto;
  }
  .spacing{
    height: fit-content !important;
  }
  .footer-div{
    display: block !important;
  }
  
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 50%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.youtube:hover {
  background-color: #d91b1b;
}
.social-icons a.whatsapp:hover {
  background-color: #35bb2f;
}
.social-icons a.instagram:hover {
  background-color: #ec4f8a;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
  .cover-img-apps a div{
    width: 7em !important;
  }
  
}

/* ----- Header---------- */
.header {
  width: 100%;
  height: 5em;
  padding: 5px;
}

.header-top {
  display: flex;
  width: 90%;
  height: 4em;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-content: center;
}

.header-nav {
  width: 30%;
  margin: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: gray;
  cursor: pointer;
}

.header-addProduct {
  /* background-color: aquamarine; */
  width: 100%;
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.page-item.active .page-link {
  background-color: #049eff !important;
  color: "#000" !important;
  border-color: #049eff !important;
}
.spacing{
  flex-direction: column !important;
  justify-content: center !important;
}
.height-fix{
  height: fit-content !important;
}
.spacing h1{
  text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 40px;
}
@media (min-width: 350px) and (max-width:499px) {
  .footer-container-bottom{
    margin-top: 305px;
  }
}
@media (min-width: 500px) and (max-width:599px) {
  .footer-container-bottom{
    margin-top: 246px;
  }
}
@media (min-width: 600px) and (max-width:900px) {
  .footer-container-bottom{
    margin-top:192px;
  }
}
@media (min-width:1600px){
  .card{
    width: 32rem !important;
  }
  .card img{
    max-height: 330px !important;
  }
  .height-fix{
    gap: 12px;
  }
  .container{
    height: 100% !important;
    max-width: 1610px !important;
  }
  .landing-page-cards-wrap{
    margin-top: 8em;
  }
}
@media (min-width:900px){
  .container{
    height: 100% !important;
  }
}