.imgUploadLbl {
  border: 2px dashed black;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  width: 67px;
  height: 67px;
}
.imgUploadLbl {
  span {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    padding-bottom: 0;
    line-height: 16px;
  }
}

.imgUploadLbl {
  p {
    font-size: 10px;
    margin: 0;
    padding: 0;
  }
}
.addAtrTxt {
  text-align: center;
  border: 2px dashed;
  border-radius: 4px;
  cursor: pointer;
}
.tglBtn {
  margin: 20px 0px;
  background-color: #049eff !important;
  border: none !important;
  border-width: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
