.MainContainer {
  /* height: 10%; */
  border-radius: 8px;
  background-color: #fff;
  /* padding: 20px 10px; */
}
.row {
  height: 100%;
  justify-content: center;
  align-items: center;
}
#dropdown-menu-align-right {
  background-color: #fff;
  color: rgb(133, 10, 10);
  border: solid 1px #cccccc;
  font-size: 0.8rem;
  padding: 8px 20px;
}
.datePicker {
  width: 40px;
  height: 40px;
  margin: 2px 0 2px 24px;
  padding: 10px 11px;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.noDataContainer {
  text-align: center;
  p {
    color: #a9a9a9;
    font-size: 22px;
  }
}
